<template>
    <div v-if="!product || !product.availableCountries || product.availableCountries.length === 0">
        <p>Please select a product that has available countries set.</p>
    </div>
    <div v-else>
        <template v-if="activeStep === 0">
            <h2>Please select what applications this product should be added to.</h2>
            <div class="d-flex">
                <div class="flex-grow-1 mr-2">
                    <pretty-select
                        data-e2e="catalogApplicationSelector"
                        v-if="shops && shops.length"
                        :options="availableShops"
                        :value="selectedShops"
                        :multiple="true"
                        @input="selectShops"
                        label="applicationName"
                        ref="applications"
                    ></pretty-select>
                </div>
                <mercur-button class="btn btn-yellow" @click.prevent="selectAll">Select all</mercur-button>
            </div>
            <div class="mt-auto">
                <mercur-button
                    data-e2e="catalogApplicationSelectorNextButton"
                    class="btn btn-primary"
                    @click="$emit('update:activeStep', activeStep + 1)"
                    :disabled="selectedShops.length === 0">
                    Next
                </mercur-button>
            </div>
        </template>
        <template v-if="activeStep === 1">
            <h2>Select agreements</h2>
            <div v-if="isLoadingAgreements">
                <small>Loading agreements...</small>
            </div>
            <div v-else>
                <div data-e2e="catalogAgreementsSelectCheckboxes" v-for="selectedShop in selectedShops" :key="selectedShop.applicationId">
                    <p>{{selectedShop.applicationName}}</p>
                    <template v-if="Object.keys(availableAgreements[selectedShop.applicationId]).length">
                        <mercur-checkbox
                            v-for="(availableAgreement, key) in availableAgreements[selectedShop.applicationId]"
                            :key="key"
                            class="mr-2"
                            v-model="selectedAgreementsForShop[selectedShop.applicationId]"
                            :value="availableAgreement.agreementId"
                            @change="handleAgreementSelection(selectedShop.applicationId, $event)"
                        >
                            {{availableAgreement.agreementName}}
                        </mercur-checkbox>
                    </template>
                    <small v-else class="pl-4">
                        Skipping this shop since there are no agreements available for this shop.
                    </small>
                </div>
            </div>
            <div class="mt-auto">
                <mercur-button
                    data-e2e="catalogApplicationSelectorNextStep1Button"
                    class="btn btn-primary"
                    @click="$emit('update:activeStep', activeStep + 1)">
                    Next
                </mercur-button>
            </div>
        </template>
        <template v-if="activeStep === 2">
            <h2>Select the countries per agreement</h2>
            <table class="table">
                <tr>
                    <th>Application</th>
                    <th>Agreement</th>
                    <th>Countries</th>
                </tr>
                <template v-for="(selectedShop, shopKey) in selectedShops">
                    <tr v-for="(agreementId, agreementKey) in selectedAgreementsForShop[selectedShop.applicationId]" :key="`${shopKey}_${agreementKey}`">
                        <td class="w-25">
                            {{selectedShop.applicationName}}
                        </td>
                        <td class="w-25">
                            {{availableAgreements[selectedShop.applicationId][agreementId].agreementName}}
                        </td>
                        <td class="w-50">
                            <div class="d-flex">
                                <pretty-select
                                    class="w-75 mr-1"
                                    :options="availableAgreements[selectedShop.applicationId][agreementId].countryCodes.filter(countryCode => !selectedCountriesForAgreementShop[selectedShop.applicationId][agreementId].includes(countryCode))"
                                    :multiple="true"
                                    v-model="selectedCountriesForAgreementShop[selectedShop.applicationId][agreementId]"
                                    :ref="`${selectedShop.applicationId}_${agreementId}_country_selector`"
                                    @input="emitValue"
                                ></pretty-select>
                                <mercur-button
                                    data-e2e="catalogApplicationSelectorSelectAllCountries"
                                    class="w-25 btn btn-sm btn-primary"
                                    @click="selectAllCountriesForAgreementShop(selectedShop.applicationId, agreementId)"
                                >Select all</mercur-button>
                            </div>
                        </td>
                    </tr>
                </template>
            </table>
        </template>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import PrettySelect from '../../utils/PrettySelect'
import CONFIG from '@root/config'

export default {
    name: 'ApplicationSelector',
    props: {
        value: {
            required: false,
            type: [Array, null],
            default: () => ([]),
        },
        product: {
            type: Object,
        },
        activeStep: {
            default: 0,
        },
    },
    components: { PrettySelect },
    data () {
        return {
            selectedShops: [],
            selectedAgreementsForShop: {},
            selectedCountriesForAgreementShop: {},
            availableAgreements: {},
            isLoadingAgreements: false,
        }
    },
    computed: {
        ...mapState('shop', ['shops']),
        availableShops () {
            return this.shops.filter(application => {
                if (application.organisationId !== this.selectedShop.organisationId) {
                    return false
                }
                return this.selectedShops === null || !this.selectedShops.some(selectedApplication => selectedApplication.applicationId === application.applicationId)
            })
        },
    },
    methods: {
        ...mapActions('shop', ['setShop', 'fetchShops']),
        emitValue () {
            this.$emit('input', this.selectedShops.map(application => {
                const applicationId = application.applicationId
                const agreements = []
                this.selectedAgreementsForShop[applicationId].forEach((data, index) => {
                    agreements[index] = {
                        agreementId: data,
                        shopHash: this.availableAgreements[applicationId][data].shopHash,
                        countries: this.selectedCountriesForAgreementShop[applicationId][data],
                    }
                })
                return {
                    applicationId,
                    applicationName: application.applicationName,
                    organisationId: application.organisationId,
                    agreements,
                }
            }).filter(application => application.agreements.length))
        },
        selectAllCountriesForAgreementShop (applicationId, agreementId) {
            this.selectedCountriesForAgreementShop[applicationId][agreementId] = this.availableAgreements[applicationId][agreementId].countryCodes

            this.$nextTick(() => {
                this.$refs[`${applicationId}_${agreementId}_country_selector`][0].updateValues()
            })
            this.emitValue()
        },
        handleAgreementSelection (applicationId, agreementIds) {
            if (!this.selectedCountriesForAgreementShop[applicationId]) {
                this.$set(this.selectedCountriesForAgreementShop, applicationId, {})
            }

            this.$set(this.selectedCountriesForAgreementShop, applicationId, Object.fromEntries(agreementIds.map(agreementId => {
                const currentSelection = this.selectedCountriesForAgreementShop[applicationId][agreementId]
                return [agreementId, currentSelection || []]
            })))
        },
        selectShops (shops) {
            this.$set(this, 'selectedShops', shops)
            shops.forEach(shop => {
                if (!this.value.length) {
                    this.$set(this.selectedAgreementsForShop, shop.applicationId, [])
                }
                const selectedAgreements = this.value.filter(({ applicationId }) => applicationId === shop.applicationId)
                selectedAgreements.forEach(agreement => {
                    if (!this.selectedCountriesForAgreementShop[agreement.applicationId]) {
                        this.$set(this.selectedCountriesForAgreementShop, agreement.applicationId, {})
                    }
                    this.$set(this.selectedCountriesForAgreementShop[agreement.applicationId], agreement.agreementId, agreement.assignedCountries)
                })
                this.$set(this.selectedAgreementsForShop, shop.applicationId, selectedAgreements.map(({ agreementId }) => agreementId))
            })
            this.isLoadingAgreements = true
            Promise.all(shops.map(shop => {
                return this.getAgreementsForApplication(shop).then(agreements => {
                    this.$set(this.availableAgreements, shop.applicationId, agreements)
                })
            })).then(() => {
                this.isLoadingAgreements = false
            })
        },
        async getAgreementsForApplication (application) {
            return this.post(CONFIG.API.ROUTES.CATALOG.GET_AGREEMENTS_FOR_APPLICATION, {
                application,
            }).then(agreements => {
                return agreements
            })
        },
        selectAll () {
            this.selectShops(this.availableShops)
            this.$nextTick(() => {
                this.$refs.applications.updateValues()
            })
        },
    },
    created () {
        this.$emit('update:activeStep', 0)
        if (this.value.length) {
            const selectedApplicationIds = this.value.map(application => application.applicationId)
            this.selectShops(this.shops.filter(application => selectedApplicationIds.includes(application.applicationId)))
        }

        if (this.shops.length) {
            return
        }

        this.fetchShops()
    },
}
</script>

<style lang="scss" scoped>
h2 {
    font-weight: 400;
}
.pretty-select {
    height: auto;

    /deep/ .v-select {
        height: auto;
    }
}
</style>
