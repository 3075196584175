<template>
    <div>
        <mercur-input
            v-model="attributeNameSearch"
            data-e2e="catalogProductNameAttributeInput"
            @blur="attributeNameSearchResultsOpen = false"
            @focus="attributeNameSearchResultsOpen = true">
            Product attributes</mercur-input>
        <mercur-menu :clickOutside="false" :isActive="attributeNameSearchResultsOpen">
            <div data-e2e="catalogProductNameAttributeList" slot="dropdown" >
                <mercur-item
                    v-for="attributeName in attributeNamesFiltered" :key="attributeName"
                    @click.native="selectAttributeName(attributeName)"
                >{{attributeName}}</mercur-item>
                <mercur-item v-if="(!attributeNamesFiltered  || attributeNamesFiltered.length === 0)"><em>No results</em></mercur-item>
            </div>
        </mercur-menu>
        <div v-for="(selectedAttributeOptions, attributeName) in filterModel" :key="attributeName">
            <h5 class="c-catalog-attributes-filter__title">{{attributeName}}</h5>
            <pretty-select
                class="attribute-options-selector"
                :options="attributes[attributeName].filter(attributeOption => !filterModel[attributeName].includes(attributeOption.option))"
                v-model="filterModel[attributeName]"
                :reduce="attributeOption => attributeOption.option"
                label="option"
                :multiple="true"
                @input="emitFilterModel"
                placeholder="Search options"
                :ref="`attribute-option-selector_${attributeName}`"
            ></pretty-select>
            <mercur-chip
                data-e2e="selectedAttribute"
                v-for="(selectedAttribute, key) in selectedAttributeOptions"
                :key="selectedAttribute"
                :deletable="true"
                @delete="removeAttributeOption(attributeName, key)"
                class="ml-0 mt-2 mr-5 bg-yellow"
            >
            {{selectedAttribute}}
            </mercur-chip>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import PrettySelect from '../../utils/PrettySelect'

export default {
    name: 'CatalogAttributesFilter',
    components: { PrettySelect },
    data () {
        return {
            attributes: null,
            attributeNameSearch: null,
            attributeNameSearchResultsOpen: false,
            attributeNames: null,
            attributeOptionsSearch: {},
            filterModel: {},
        }
    },
    methods: {
        emitFilterModel () {
            this.filterModel = Object.fromEntries(Object.entries(this.filterModel).filter(([key, value]) => value.length))
            this.$emit('input', this.filterModel)
        },
        selectAttributeName (attributeName) {
            this.$set(this.filterModel, attributeName, [])
            this.$set(this, 'attributeNameSearch', null)
        },
        removeAttributeOption (attributeName, key) {
            this.$delete(this.filterModel[attributeName], key)
            this.$nextTick(() => {
                try {
                    this.$refs[`attribute-option-selector_${attributeName}`][0].updateValues()
                } catch (e) { }
            })
            this.emitFilterModel()
        },
    },
    computed: {
        attributeNamesFiltered () {
            if (!this.attributeNames || !this.attributeNameSearch) {
                return null
            }
            const queryString = this.$options.filters.beautify(this.attributeNameSearch).toLowerCase()
            const currentSelectedAttributes = Object.keys(this.filterModel)

            return this.attributeNames.filter(attributeName => {
                return this.$options.filters.beautify(attributeName).toLowerCase().includes(queryString) && currentSelectedAttributes.includes(attributeName) === false
            }).slice(0, 5)
        },
    },
    created () {
        this.post(CONFIG.API.ROUTES.CATALOG.ATTRIBUTES.OVERVIEW, {}).then(({ data }) => {
            this.$set(this, 'attributes', data)
            this.$set(this, 'attributeNames', Object.keys(data))
        })
    },
}
</script>

<style lang="scss" scoped>
.attribute-options-selector {

    /deep/ .vs__selected {
        display: none;
    }
}
</style>
