<template>
    <div>
        <mercur-input
            data-e2e="catalogProductNameFilterInput"
            v-model="productFilter"
            @input="emitFilterModel">
            Product Template Name
        </mercur-input>
        <mercur-input
            v-if="overviewType != 'marketplace'"
            data-e2e="catalogProductNameFilterInput"
            v-model="variantFilter"
            @input="emitFilterModel">
            Product Variant Name
        </mercur-input>
        <mercur-input
            v-if="overviewType != 'marketplace'"
            v-model="variantSlugFilter"
            @input="emitFilterModel">
            Product Variant Slug
        </mercur-input>
        <catalog-attributes-filter
            v-model="attributeFilter"
            @input="emitFilterModel"
        ></catalog-attributes-filter>
    </div>
</template>

<script>
import CatalogAttributesFilter from './CatalogAttributeFilter'
const debounce = require('lodash.debounce')

export default {
    name: 'CatalogFilters',
    components: { CatalogAttributesFilter },
    props: {
        overviewType: {
            type: String,
        },
    },
    data () {
        return {
            productFilter: null,
            variantFilter: null,
            attributeFilter: null,
            variantSlugFilter: null,
        }
    },
    methods: {
        emitFilterModel: debounce(function () {
            const filterModel = {}
            if (this.productFilter) {
                filterModel.productName = {
                    filter: this.productFilter,
                    filterType: 'text',
                    type: 'contains',
                }
            }
            if (this.variantFilter) {
                filterModel.variantName = {
                    filter: this.variantFilter,
                    filterType: 'text',
                    type: 'contains',
                }
            }
            if (this.variantSlugFilter) {
                filterModel.variantSlug = {
                    filter: this.variantSlugFilter,
                    filterType: 'text',
                    type: 'contains',
                }
            }
            if (this.attributeFilter) {
                filterModel.currentAttributes = this.attributeFilter
            }
            this.$emit('input', filterModel)
        }, 500),
    },
}
</script>
